import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/engineering.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'MLM Group',
    url: '/mlm-group',
  },
]

const CapsCases = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Caps Cases"
      description="R&D Caps Cases"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Caps Cases"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Caps Cases" />
          <CaseStudyContent
            heading="Packaging Design & Manufacture"
            paragraph='Before talking to RDA, we had previously completed our own claims, and whilst we were aware of the scheme, it was viewed as something that wouldn’t be a valuable use of our limited resources.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  However, RDA exceeded our expectations in every way and were extremely thorough and professional, and the continual persistence to get us to explain our projects and the hassle-free nature of the process was valuable in our experience with them. Lee Rhodes was particularly an asset to the team and we would - and have - recommended RDA to friends and peers.
                </p>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default CapsCases
